import { Component, Input } from "@angular/core";
import { setDelaysChartStepSize } from "../core/models/helpers";

@Component({
  selector: "app-bars-chart",
  templateUrl: "./bars-chart.component.html",
  styleUrls: ["./bars-chart.component.scss"],
})
export class BarsChartComponent {
  @Input() delays_data: any;

  get_chart_options() {
    if (!this.delays_data) return {};
    console.log("bars data is ", this.delays_data.datasets[0].data);
    let new_data_set = this.delays_data;
    let step_size = 1;
    const numbersOnly = new_data_set.datasets[0].data.filter(
      (item: any) => typeof item === "number",
    );
    let max = 1;
    if (!new_data_set) {
      new_data_set = [];
    } else {
      if (numbersOnly.length > 1) {
        max = Math.max(numbersOnly);
        const min = Math.min(numbersOnly);
        step_size = (max - min) / 10;
      } else if (numbersOnly.length === 1) {
        let single_value = numbersOnly[0];
        max = single_value;
        step_size = Math.round((single_value * 2) / 15);
      }
    }

    //step_size = roundToNearestMultipleBasedOnValue(step_size);

    let max_value = smallestMultipleOverM(step_size, max);
    console.log("bars step size is ", step_size);
    console.log("bars step max is ", max_value);

    return setDelaysChartStepSize(step_size, max_value);
  }
}

export function smallestMultipleOverM(N: number, M: number) {
  let multiple = M;

  while (true) {
    if (multiple % N === 0) {
      return multiple;
    }
    multiple++;
  }
}

export function roundToNearestMultipleBasedOnValue(number: number) {
  return number;
  console.log("step received is ", Math.round(number / 10));

  const baseTen = range(1, 10).map((n: number) => Math.pow(10, n));
  console.log("powers = ", baseTen);
  const firstThatsBigger = Math.min(
    ...baseTen.filter((n: number) => number < n),
  );

  console.log(
    "first bigger than is ",
    firstThatsBigger,
    " stepsize is ",
    number,
    " returning ",
    Math.round(Math.floor(firstThatsBigger)) / 10,
  );

  return Math.round(Math.floor(firstThatsBigger)) / 10;
}

function range(start: number, end: number) {
  const numbers = [];
  for (let i = start; i <= end; i++) {
    numbers.push(i);
  }
  return numbers;
}
