import {
  Component,
  Input,
  Output,
  EventEmitter,
  OnChanges,
  SimpleChanges,
} from "@angular/core";
import {
  BRMColumn,
  ListBRMColumn,
  StringBRMColumn,
  brmColumnType,
} from "../brm/columns";
import { CheckboxChangeEvent } from "primeng/checkbox";
import { SingleEdit } from "../brm/undoQueue";
import { capitalizeFirstLetter } from "../core/models/helpers";
@Component({
  selector: "app-brm-cell-editor",
  templateUrl: "./brm-cell-editor.component.html",
  styleUrls: ["./brm-cell-editor.component.scss"],
})
export class BrmCellEditorComponent {
  @Input() editingState = false;
  @Input() column: BRMColumn = new StringBRMColumn("recode");
  @Input() displayValue: any;
  @Input() primary_key: any;
  @Input() width: number = 100;
  @Output() newChange = new EventEmitter<any>();

  brmColumnType = brmColumnType;
  date: Date | undefined;

  ngOnInit() {
    if (this.column.type === brmColumnType.dateTime) {
      this.date = new Date(this.getDisplayValue());
    }
  }

  capitalize(text: string) {
    return capitalizeFirstLetter(text);
  }

  getCellKeyValue() {
    console.log("the key is ", this.primary_key);
    const key_value = {} as Record<string, any>;
    for (const key of this.primary_key) {
      key_value[key] = this.displayValue[key];
    }

    console.log("key value is", key_value);
    return key_value;
  }

  change(event: any | CheckboxChangeEvent) {
    console.log("new change :", event);
    const oldValue = this.displayValue[this.column.getField()];
    let id = this.getCellKeyValue();
    if ("target" in event) {
      let newValue = (event.target as HTMLInputElement).value;
      if (newValue === undefined) return;
      if (oldValue === newValue) return;
      this.newChange.emit(
        new SingleEdit(
          id,
          this.column.field,
          (event.target as HTMLInputElement).value,
          this.displayValue["original_" + this.column.getField()],
        ),
      );
    } else {
      if (this.column.type === brmColumnType.boolean) {
        if (event.checked === undefined) return;
        this.newChange.emit(
          new SingleEdit(
            id,
            this.column.field,
            event.checked,
            this.displayValue["original_" + this.column.getField()],
          ),
        );
      } else {
        //@TODO assumes that it's a list column (but make sure it can't be a number field => make sure that number fields are not editable)
        if (event.value === undefined) return;
        this.newChange.emit(
          new SingleEdit(
            id,
            this.column.field,
            event.value,
            this.displayValue["original_" + this.column.getField()],
          ),
        );
      }
    }
  }

  getDisplayValue() {
    if (this.column.type === brmColumnType.boolean) {
      console.log(this.displayValue, this.column.getField());
      return this.displayValue[this.column.getField()];
    } else if (this.column.type === brmColumnType.dateTime) {
      return new Date(this.displayValue[this.column.getField()]);
    } else if (this.column.type === brmColumnType.list) {
      return (
        this.displayValue[this.column.getField()] as string
      ).toLowerCase();
    }
    return this.displayValue[this.column.getField()];
  }

  getListOptions() {
    if (this.column.type === brmColumnType.list) {
      const listColumn = this.column as ListBRMColumn;
      return listColumn.getListItems();
    }

    return [];
  }

  formatDate(date: Date) {
    return (
      date.getDate() +
      "/" +
      (date.getMonth() + 1) +
      "/" +
      date.getFullYear() +
      " " +
      date.getHours() +
      ":" +
      date.getMinutes()
    );
  }

  jsonify(object: any) {
    return JSON.stringify(object);
  }
}
