<div>
  <p-multiSelect
    [options]="this.options"
    [optionLabel]="'label'"
    [optionValue]="'value'"
    class="borderless mine_dropdown"
    [placeholder]="this.placeholder"
    [ngModel]="this.selected_value"
    (onChange)="this.inputChanged($event)"
    (onBlur)="this.blur()"
  >
    <ng-template let-country pTemplate="footer">
      <div
        class="py-2 px-3"
        *ngIf="this.enbale_other_value && this.showOtherInput()"
      >
        <p>{{ this.other_label }}</p>
        <input
          pInputText
          class="other_input"
          [placeholder]="this.other_placeholder"
          (change)="this.otherChanged($event)"
          [(ngModel)]="this.other_value"
        />
      </div>
    </ng-template>
  </p-multiSelect>
</div>
