import { Component } from "@angular/core";
import { SourceState } from "../shared/source-card/source-card.component";
import { ActivatedRoute } from "@angular/router";
import { SourceService } from "../core/services/source.service";
import {
  wrapArrayInTreeNode,
  wrapMultipleInTreeNode,
} from "../mine-table/data-group";
import {
  channel_list,
  data_type_list,
  getCountries,
  granularity_list,
  update_frequency_list,
} from "../landing/my/filters-data";
import {
  stepInformation,
  stepsInformation,
  stepState,
} from "../shared/steps-component/stepsInformation";
import { forkJoin, map, ObservableInput, zip } from "rxjs";
import { buildChartData } from "../bars-chart/bars_chart_utils";
import {
  arrayDifference,
  arrayIsAll,
  removeAll,
  reOrderSummary,
} from "../core/models/helpers";
import {
  attribute_chart_data_point_template,
  augmentKPIsWithPaths,
  convertAllKPIsToIntegers,
  fixTrcTranscoding,
  get_unique_stats,
  removeGroupNameFromKPI,
} from "../quality-center-dashboard/quality-center-dashboard.component";
import {
  keysToConvert,
  replaceZeroKeyWithDash,
  spaceDecimalsInSalesKPIs,
} from "../source-details/source-details.component";
import { stepKPIsHeaders, summary_table_headers } from "../core/utils/KPIUtils";
import { create } from "lodash";
import { isNumber } from "tsparticles-engine";

@Component({
  selector: "app-update",
  templateUrl: "./update.component.html",
  styleUrls: ["./update.component.scss"],
})
export class UpdateComponent {
  summary_table_headers = summary_table_headers;

  attributeSelected($event: any, step_name: string, update: any) {
    const new_path = $event.path;
    this.selectKPIPath(new_path, step_name, update.batch_id);
  }

  getCurrentSelectedField(): string {
    return "Product";
  }

  open_tabs = [] as number[];
  closed_tabs = [];
  attribute_charts_values: Record<string, any> = {};

  accordionToggled(event: boolean, update: Record<string, any>) {
    console.log("accordion event", event, update);
    if (event) this.open_tabs.push(update["batch_id"]);
    console.log("open_tabs = ", this.open_tabs);
  }

  data_status_options = [
    {
      value: "",
      label: "FILTER BY",
      items: [
        { value: "created_by", label: "Created" },
        { value: "activated", label: "Activated" },
        { value: "deactivated", label: "Deactivated" },
        { value: "awaiting_delivery", label: "Awaiting Delivery" },
        { value: "Delayed", label: "Delayed" },
        { value: "Pending", label: "Pending" },
        { value: "in_progres", label: "In Progress" },
        { value: "completed", label: "Completed" },
      ],
    },
    {
      value: "",
      label: "SORT BY",
      items: [
        { value: "last_updated", label: "Last Updated" },
        { value: "last_modified", label: "Last Modified" },
        { value: "last_created", label: "Last Created" },
      ],
    },
  ];
  stages_list = [
    {
      value: "",
      label: "",
      items: [{ value: "all_steps", label: "All Steps", isAll: true }],
    },
    {
      value: "steps",
      label: "Steps",
      items: [
        { value: "collection", label: "Collection" },
        { value: "cleaning", label: "Cleaning" },
        { value: "consolidation", label: "Consolidation" },
        { value: "referencing", label: "Referencing" },
        { value: "recoding", label: "Recoding" },
        { value: "categorization", label: "Categorization" },
        { value: "transcoding", label: "Transcoding" },
      ],
    },
  ];

  KPIOrderByGroup: Record<string, Record<string, string[]>> = {
    consolidation: {
      cso_product: [
        "country",
        "panel",
        "channel",
        "brick",
        "atc4",
        "atc3",
        "corporation",
        "manufacturer",
        "product",
        "molecules",
        "nfc123",
        "pack",
        "gx",
        "rx",
        "volume",
        "strength",
        "launch_date",
      ],
    },
    recoding: {
      cso_product: [
        "country",
        "panel",
        "channel",
        "atc4",
        "corporation",
        "manufacturer",
        "product",
        "molecule",
        "nfc123",
        "pack",
        "volume",
        "gx",
        "rx",
        "strength",
        "launch_date",
        "country_recode",
        "panel_recode",
        "channel_recode",
        "atc4_recode",
        "corporation_recode",
        "manufacturer_recode",
      ],
    },
    categorization: {
      trc_transcoding: [
        "country",
        "channel",
        "panel",
        "category master",
        "category1",
        "category0",
        "atc4",
        "product",
        "molecule",
        "nfc123",
        "pack",
        "gx",
        "rx",
        "strength",
        "launch_date",
        "country_recode",
        "channel_recode",
        "panel_recode",
        "category master_recode",
        "category1_recode",
        "category0_recode",
        "atc4_recode",
        "product_recode",
        "molecule_recode",
        "nfc123_recode",
        "pack_recode",
        "gx_recode",
        "rx_recode",
        "strength_recode",
        "launch_date_recode",
      ],
    },
    transcoding: {
      trc_dot: [
        "molecule_recode",
        "strength_recode",
        "atc4_recode",
        "nfc123_recode",
        "dot_g_factor",
        "dot_l_factor",
      ],
      trc_pg: [
        "molecule_recode",
        "corporation",
        "product_recode",
        "product_group1",
        "product_group2",
      ],
    },
    referencing: {
      rec_molecules: [
        "molecules",
        "molecules_recode",
        "high_priority_molecules",
      ],
      rec_gx: ["country", "panel", "gx", "gx_recode"],
      rec_rx: ["country", "panel", "rx", "rx_recode"],
      rec_atc4: ["atc4", "atc4_recode"],
      rec_nfc123: ["nfc123", "nfc123_recode"],
      rec_corporation: ["corporation", "corporation_recode"],
      rec_channel: ["country", "channel", "channel_recode"],
      rec_su: ["country", "pack_recode", "nfc123_recode", "su_recode"],
    },
  };

  sources: any[] = [];

  selected_status: string | undefined;

  filters = {
    countries: {
      name: "countries",
      selected_values: ["all"],
      old_selected_values: ["all"],
      placeholder: "Geography",
      options: [] as any[],
    },
    data_types: {
      name: "data_types",
      selected_values: ["all"],
      old_selected_values: ["all"],
      placeholder: "Data Type",
      options: [] as any[],
    },
    channels: {
      name: "channels",
      selected_values: ["all"],
      old_selected_values: ["all"],
      placeholder: "Channel",
      options: [] as any[],
    },
    granularities: {
      name: "granularities",
      selected_values: ["all"],
      old_selected_values: ["all"],
      placeholder: "Granularity",
      options: [] as any[],
    },
    frequencies: {
      name: "frequencies",
      selected_values: ["all"],
      old_selected_values: ["all"],
      placeholder: "Frequency",
      options: [] as any[],
    },
  } as Record<string, any>;

  updates = [] as any[];

  stepKPIsHeaders = stepKPIsHeaders;

  salesInformationHeaders = [
    { header: "Table", field: "sales" },
    { header: "Total Sales (Latest Period)", field: "latest_sales_sum" },
    {
      header: "Current Total Sales VS Previous File",
      field: "total_sales_sum",
    },
    {
      header: "Latest Preiod VS Previous Period",
      field: "sales_sum_rolling_1_period_percent",
    },
    {
      header: "Latest Sales VS Previous Month",
      field: "sales_sum_rolling_1_month_percent",
    },
    {
      header: "Latest Sales VS 3 Previous Month",
      field: "sales_sum_rolling_3_months_percent",
    },
    {
      header: "Latest Sales Rolling 12 Month",
      field: "sales_sum_rolling_12_months_percent",
    },
  ];

  stepKPIs: Record<string, any> = {
    cleaning: {},
    recoding: {},
  };

  countries: any[] = [];
  data_types: any[] = [];
  channels: any[] = [];
  granularities: any[] = [];
  frequencies: any[] = [];
  update_statuses: any[] = [
    { value: "last_updated", label: "Last Updated" },
    { value: "last_modified", label: "Last Modified" },
    { value: "last_created", label: "Last Created" },
  ];

  constructor(
    private route: ActivatedRoute,
    private sourceService: SourceService,
  ) {}

  getUniqueValuesFromFilter(filterName: string) {
    const filterValues = new Set();
    this.updates.map((update: any) =>
      filterValues.add(update.data[filterName]),
    );

    return Array.from(filterValues) as string[];
  }

  loadFilters() {
    return this.sourceService.getSourceFilters().pipe(
      map((res) => {
        console.log(this.getUniqueValuesFromFilter("country_code"));
        this.countries = [
          { label: "All", value: "all" },
          ...getCountries(res.countries),
        ];

        this.filters["countries"].options = [
          { label: "All", value: "all" },
          ...getCountries(this.getUniqueValuesFromFilter("country_code")),
        ];

        this.data_types = [
          { label: "All", value: "all", disabled: false },
          ...this.disabledIfNotInArray(
            data_type_list,
            this.getUniqueValuesFromFilter("data_type"),
          ),
        ];

        this.filters["data_types"].options = [
          { label: "All", value: "all" },
          ...this.disabledIfNotInArray(
            data_type_list,
            this.getUniqueValuesFromFilter("data_type"),
          ),
        ];

        this.channels = [
          { label: "All", value: "all", disabled: false },
          ...this.disabledIfNotInArray(
            channel_list,
            this.getUniqueValuesFromFilter("channel"),
          ),
        ];

        this.filters["channels"].options = [
          { label: "All", value: "all" },
          ...this.disabledIfNotInArray(
            channel_list,
            this.getUniqueValuesFromFilter("channel"),
          ),
        ];
        console.log(this.filters["channels"]);

        this.granularities = [
          { label: "All", value: "all", disabled: false },
          ...this.disabledIfNotInArray(
            granularity_list,
            this.getUniqueValuesFromFilter("granularity"),
          ),
        ];

        this.filters["granularities"].options = [
          { label: "All", value: "all" },
          ...this.disabledIfNotInArray(
            granularity_list,
            this.getUniqueValuesFromFilter("granularity"),
          ),
        ];

        this.frequencies = [
          { label: "All", value: "all", disabled: false },
          ...this.disabledIfNotInArray(
            update_frequency_list,
            this.getUniqueValuesFromFilter("update_frequency"),
          ),
        ];

        this.filters["frequencies"].options = [
          { label: "All", value: "all" },
          ...this.disabledIfNotInArray(
            update_frequency_list,
            this.getUniqueValuesFromFilter("update_frequency"),
          ),
        ];
      }),
    );
  }

  subject: any;

  ngOnInit() {
    console.log(
      "predicate",
      this.cleaningPathsEquality(
        "cleaning.FRD-N-Greece-GPI-202305-clean.speciality",
        "cleaning.FRD-N-Greece-GPI-202001-clean.speciality",
      ),
    );
    this.route.data.subscribe((data) => {
      const status = data["status"];

      if (status) this.selected_status = status;

      if (this.selected_status) {
        this.loadUpdates(this.selected_status);
      }

      this.subject = new WebSocket("wss://live.mine.cloud.mangabey.io");

      this.subject.onerror = (event: any) => {
        console.log("error, ", event);
      };
      this.subject.onopen = () => {
        console.log("ws: Websocket connection established.");
      };

      this.subject.onmessage = (event: any) => {
        //TODO make sure you only start updating after initial load is done (and make sure not interleaving updates).
        console.log("ws: Received message:", event.data);
        if (this.selected_status) this.loadUpdates(this.selected_status);
      };
    });
  }

  disabledIfNotInArray(initialArray: any[], filterArray: any[]) {
    return initialArray.map((item: any) =>
      filterArray.findIndex((value) => value === item.value) === -1
        ? { ...item, disabled: true }
        : { ...item, disabled: false },
    );
  }

  loadUpdates(status: string) {
    console.log("loading updates");
    this.sourceService.getUpdates(status).subscribe((res: any) => {
      console.log(res);
      this.updates = this.cleanUpdates(res);
      zip(
        this.loadStepKPIs(),
        this.loadStepSalesInformation(),
        this.loadSummaryTables(),
        this.initDataDelivery(),
        this.loadKPIsEvolutionInformation(),
        this.loadFilters(),
      ).subscribe(() => {
        this.updates = this.updates.map((update: any) => ({
          ...update,
          KPIs: { ...this.buildUpdateKPIs(update) },
        }));
        this.initSalesInformation();
        console.log("final KPIs", this.stepKPIs);
      });
    });
  }

  augmentStepsKPIsWithPath(stepKPIs: Record<string, any[]>) {
    const final_steps_KPIS: Record<string, any[]> = {};

    Object.keys(stepKPIs).map(
      (step_name: string) =>
        (final_steps_KPIS[step_name] = augmentKPIsWithPaths(
          stepKPIs[step_name],
        )),
    );

    return final_steps_KPIS;
  }

  augmentNestedStepsKPIsWithPath(
    stepKPIs: Record<string, Record<string, any[]>>,
  ) {
    const final_steps_KPIS: Record<string, any[]> = {};

    Object.keys(stepKPIs).map(
      (step_name: string) =>
        (final_steps_KPIS[step_name] = augmentKPIsWithPaths(
          stepKPIs[step_name],
        )),
    );

    return final_steps_KPIS;
  }

  filterKPIsOnSourceName(KPIs: any[], source_name: string) {
    // this code assumes that KPIs are all for cleaning
    return KPIs.filter((KPI: any) => {
      KPI.path.contains(source_name);
    });
  }

  selected_path = "";
  KPIsByPath = {} as Record<string, Record<string, any[]>>;
  filterBatchSteps(
    stepsKPIS: Record<string, any[]>,
    predicate: (a: any) => boolean,
  ) {
    return Object.values(stepsKPIS)
      .map((singleStepKPIs: any[]) => singleStepKPIs.filter(predicate))
      .flat();
  }

  parseCleaningPath(cleaningPath: string) {
    console.log("new_path", cleaningPath.split("-"));
    return cleaningPath.split("-").slice(0, 4).join("-");
  }

  cleaningPathsEquality(firstPath: string, secondPath: string) {
    const parsedFirstPath = firstPath.split("-");
    parsedFirstPath.splice(4, 1);
    const parsedSecondPath = secondPath.split("-");
    parsedSecondPath.splice(4, 1);
    console.log("eq: first path is ", parsedFirstPath);
    console.log("eq: second path is ", parsedSecondPath);

    return parsedFirstPath.join("") === parsedSecondPath.join("");
  }

  selectKPIPath(kpi_path: string, step_name: string, batch_id: string) {
    this.selected_path = kpi_path;
    let KPIsHistory = this.KPIsByPath[step_name];
    let currentPathKPIs = [] as any[];

    if (kpi_path.startsWith("cleaning")) {
      currentPathKPIs = this.filterBatchSteps(KPIsHistory, (kpi: any) =>
        this.cleaningPathsEquality(kpi.path, kpi_path),
      );
    } else
      currentPathKPIs = this.filterBatchSteps(
        KPIsHistory,
        (kpi: any) => kpi.path === kpi_path,
      );

    console.log("current path info ,", currentPathKPIs);

    currentPathKPIs = currentPathKPIs.sort((kpiA: any, kpiB: any) =>
      parseInt(kpiA["batch_id"]) > parseInt(kpiB["batch_id"]) ? 1 : -1,
    );

    const attributes = ["unique_values", "current_records", "percent_blanks"];
    const labelsAndData = {} as Record<
      string,
      { labels: string[]; data: any[] }
    >;

    attributes.map((attribute: string) => {
      labelsAndData[attribute] = { data: [], labels: [] };
      currentPathKPIs.map((KPI: any) => {
        labelsAndData[attribute].data.push(KPI[attribute]);
        labelsAndData[attribute].labels.push("Upd #" + KPI["batch_id"]);
      });
    });

    attributes.map((attribute: string) => {
      this.attribute_charts_values[attribute] = {
        ...this.attribute_charts_values[attribute],
        datasets: [
          {
            ...attribute_chart_data_point_template,
            data: labelsAndData[attribute].data,
          },
        ],
        labels: labelsAndData[attribute].labels,
      };
    });
    this.attribute_charts_values = { ...this.attribute_charts_values };
  }

  loadKPIsEvolutionInformation() {
    return this.sourceService.getQCStats().pipe(
      map((res: any) => {
        res = get_unique_stats(res);
        res = convertAllKPIsToIntegers(res);
        res = augmentKPIsWithPaths(res);
        res = fixTrcTranscoding(this.groupBy(res, "batch_step"));
        //@TODO fix extras like rec_su/trc_transcoding

        console.log("attributes information => ", res);
        console.log("grouped", this.groupKPIsEvolutionByBatch(res));
        const batchGrouping = this.groupKPIsEvolutionByBatch(res);
        this.KPIsByPath = batchGrouping;
        console.log("final KPI evolution :", this.KPIsByPath);
      }),
    );
  }

  applyRecord(record: Record<string, any[]>, lambda: (item: any) => any) {
    const newRecord = {} as Record<string, any[]>;

    Object.keys(record).map((key: string) => {
      newRecord[key] = record[key].map(lambda);
    });

    return newRecord;
  }

  attributesEvolutionByStep: Record<string, any[]> = {};

  unique = (arr: any[]) => Array.from(new Set(arr));

  groupKPIsEvolutionByBatch(res: Record<string, any[]>) {
    const final_record: Record<string, Record<string, any[]>> = {};

    Object.keys(res).map((step_name: string) => {
      const uniquesBatchs = this.unique(
        res[step_name].map((KPI: any) => KPI["batch_id"]),
      );

      const groupedKPIs: Record<string, any[]> = {};
      uniquesBatchs.map((batch_id: string) => (groupedKPIs[batch_id] = []));

      res[step_name].map((KPI: any) => {
        groupedKPIs[KPI["batch_id"]].push(KPI);
      });
      final_record[step_name] = groupedKPIs;
    });
    return final_record;
  }

  reOrderSales(salesInformation: { sales_kpis: any }) {
    const salesInfo = salesInformation.sales_kpis.map((info: any) => ({
      ...info,
      sales: this.capitalizeFirstLetter(
        info.sales.replace("Sales in ", "").trim(),
      ),
    }));
    const order = [
      "local currency",
      "unit",
      "standard unit",
      "counting unit",
      "dot",
      "prescription",
    ];

    const orderedSalesInformation = new Set<any>();

    for (const sale_name of order) {
      const item = salesInfo.find(
        (sales_data: any) => sales_data.sales.toLowerCase() === sale_name,
      );
      if (item) {
        orderedSalesInformation.add(item);
        console.log(item);
      }
    }

    //Add any remaining items
    const remaining = salesInfo.filter(
      (sales_data: any) => !orderedSalesInformation.has(sales_data),
    );
    for (const item of remaining) {
      orderedSalesInformation.add(item);
    }

    return Array.from(orderedSalesInformation);
  }

  isCompleted(update: any) {
    return update.completion_date !== undefined;
  }

  getStatusLabel(status: string) {
    const statusLabels = {
      pending: "Pending",
      "in-progress": "In Progress",
      completed: "Completed",
    } as Record<string, string>;

    return statusLabels[status];
  }

  getBatchLabel(update: any) {
    if (isNaN(update.batch_id)) {
      return "Next Update";
    } else return `Update # ${update.batch_id}`;
  }

  formatDate(date: Date) {
    return `${this.addLeadingZero(date.getDate())}.${this.addLeadingZero(
      date.getMonth() + 1,
    )}.${this.addLeadingZero(date.getFullYear())} ${this.addLeadingZero(
      date.getHours(),
    )}:${this.addLeadingZero(date.getMinutes())}`;
  }

  buildUpdateKPIs(update: any) {
    const KPIs = {} as Record<string, any>;

    for (const step of this.stepsWithKPIs) {
      //KPIs[step] = this.stepKPIs[step][update.batch_id];
      KPIs[step] = this.formatKPIs(step, update.batch_id);
    }

    return KPIs;
  }

  formatKPIs(step: string, batch_id: string) {
    console.log(this.stepKPIs[step][batch_id]);
    return this.stepKPIs[step][batch_id];
  }

  updates_sales_information = {} as Record<string, any>;

  initSalesInformation() {
    for (const update of this.updates) {
      const sources = update.sources;
      const final_sources = {} as Record<string, any>;
      Object.keys(this.salesInformation)
        .filter((source: any) => sources.includes(source))
        .map(
          (source_name: string) =>
            (final_sources[source_name] = this.cleanSalesNumbers(
              this.reOrderSales(this.salesInformation[source_name]),
            )),
        );
      this.updates_sales_information[update.batch_id.toString()] =
        wrapMultipleInTreeNode(final_sources, false);
    }
  }
  cleanSalesNumbers(res: any[]): any {
    res = res.map((KPI: any) =>
      spaceDecimalsInSalesKPIs(
        replaceZeroKeyWithDash(KPI, keysToConvert),
        keysToConvert,
      ),
    );
    return res;
  }

  currentStatusConverter(currentStatus: string): SourceState {
    if (currentStatus === "in-progess") currentStatus = "in-progress";

    const conversion = {
      inactive: SourceState.Inactive,
      "in-progress": SourceState.InProgress,
      pending: SourceState.Pending,
      completed: SourceState.Completed,
    } as Record<string, SourceState>;
    return conversion[currentStatus];
  }

  getStepDropdownLabel(step_value: string) {
    if (step_value === "all_steps") return "All Steps";
    const result = this.stages_list[1].items.find(
      (item: any) => item.value === step_value,
    );
    if (result) return result.label;
    else return undefined;
  }

  updates_current_steps = {} as Record<string, string>;

  cleanUpdates(res: any) {
    const updates = [];
    console.log(res);
    for (const batch_id in res) {
      let completion_date = res[batch_id][0]["transcoding"];
      if (completion_date != "Unknown" && completion_date !== undefined)
        completion_date = new Date(completion_date);
      else completion_date = undefined;

      updates.push({
        batch_id: parseInt(batch_id),
        data: res[batch_id][0],
        //steps: this.generateSteps(res[batch_id][0]),
        start_date: new Date(res[batch_id][0]["cleaning"]),
        sources: Array.from(
          res[batch_id].map((source: any) => source.sourcename),
        ),
        completion_date: completion_date,
        validSteps: this.buildValidSteps(res[batch_id][0]),
      });

      this.updates_current_steps[parseInt(batch_id)] = "all_steps";
    }

    const sources = new Set<string>();
    updates.map((update: any) =>
      update.sources.map((source: any) => sources.add(source)),
    );
    console.log(updates);

    this.sources = Array.from(sources);

    return updates
      .sort((updateA, updateB) => {
        if (isNaN(updateA.batch_id)) return -1;
        else if (isNaN(updateB.batch_id)) return 1;

        return updateA.start_date > updateB.start_date ? -1 : 1;
      })
      .filter((update: any) => update.batch_id != "0");
  }

  proceed(update: any) {
    if (!this.canYouProceed(update)) return;

    const proceedURLs = {
      cleaning:
        "https://prod2-15.francecentral.logic.azure.com:443/workflows/06d592595bf7412c8a834dc7ad7537a9/triggers/manual/paths/invoke?api-version=2016-06-01&sp=%2Ftriggers%2Fmanual%2Frun&sv=1.0&sig=FYuJxAtAUp0q-Kcb0OOks7L8Ued2AzhSy8Zpk9VFziQ",
      referencing:
        "https://prod2-12.francecentral.logic.azure.com:443/workflows/8cf0eb7841e243d5b6e204027c3e05f8/triggers/manual/paths/invoke?api-version=2016-06-01&sp=%2Ftriggers%2Fmanual%2Frun&sv=1.0&sig=ttWWnED17d23_BPzbrAyphMC4vsXNk3cyGwtlEBYapo",
      categorization:
        "https://prod2-22.francecentral.logic.azure.com:443/workflows/b18b56139939431ca931ae2026d4b79b/triggers/manual/paths/invoke?api-version=2016-06-01&sp=%2Ftriggers%2Fmanual%2Frun&sv=1.0&sig=vAO4hpuPldZWlH56Xrm-f_xfw6dGd5-hZqvJb5Z9U9o",
    } as Record<string, string>;

    const proceedURL = proceedURLs[this.lastCompletedStep(update)];
    console.log(proceedURL);
    this.sourceService
      .post(proceedURL)
      .subscribe((res: any) => console.log(res));
  }

  lastCompletedStep(update: any, read_from_data = true) {
    let last_completed = "";
    const steps = JSON.parse(JSON.stringify(this.stages_list))[1].items.map(
      (item: any) => item.value,
    );
    steps.push("final_transcoding");

    console.log("last_completed");
    let i = 0;
    for (let step of steps) {
      if (step === "categorizing") step = "categorization";

      console.log(step);
      const value = update.data[step];
      console.log(step, value);
      if (value === "Unknown") {
        last_completed = steps[i - 1];
        break;
      }
      i++;
    }
    return last_completed;
  }

  canYouProceed(update: any) {
    const proceedableSteps = ["cleaning", "referencing", "categorization"];

    const last_completed = this.lastCompletedStep(update);
    console.log(last_completed);

    return proceedableSteps.includes(last_completed);
  }

  buildValidSteps(update: any) {
    console.log(update);
    const result = JSON.parse(JSON.stringify(this.stages_list));
    const new_items = result[1].items.map((value: any) => ({
      value: value.value,
      label: value.label,
      batch_id: update["batch_id"],
      isDisabled: update[value.value] === "Unknown",
    }));
    console.log(new_items);

    result[1].items = new_items;

    return result;
  }

  jsonify(object: any) {
    return JSON.stringify(object);
  }

  isPendingUpdate(update: any) {
    return isNaN(update.batch_id);
  }

  generateSteps(update: any) {
    const result = {
      collection: {},
      cleaning: {},
      consolidation: {},
      referencing: {},
      recoding: {},
      categorization: {},
      transcoding: {},
    };

    if (update["steps_data"] !== null) {
      return calculateStepsForEmrData(update, false) as stepsInformation;
    } else {
      for (const step of Object.keys(result)) {
        (result as any)[step] = {
          duration: 50 * 1000,
          state: stepState.completed,
        };
      }
    }
    return result as stepsInformation;
  }

  stepsWithKPIs = [
    "cleaning",
    "consolidation",
    "referencing",
    "recoding",
    "categorization",
    "transcoding",
  ];

  displayStepKPIs(update: any) {
    console.log(update);
    return this.stepsWithKPIs.includes(
      this.updates_current_steps[update.batch_id],
    );
  }

  displayDataDelivery(update: any) {
    return this.updates_current_steps[update.batch_id] === "collection";
  }

  displaySalesInfromation(update: any) {
    return this.updates_current_steps[update.batch_id] === "cleaning";
  }

  data_delivery_headers = [
    { name: "End Period", key: "latest_end_period" },
    { name: "Delivery Date", key: "latest_delivery_date" },
    {
      name: "Latest Delivery VS Period (Delays)",
      key: "latest_delivery_delay",
    },
    {
      name: "Average Delay in the Last 3 Months",
      key: "last_3_months_avg_delay",
    },
    {
      name: "Average Delay in the Last 12 Months",
      key: "last_12_months_avg_delay",
    },
  ];

  sources_delays = {} as Record<string, any>;
  delays_data = {
    labels: [
      "September 2023",
      "October 2023",
      "November 2023",
      "March 2024",
      "January 2024",
      "February 2024",
      "December 2023",
      "April 2024",
    ],
    datasets: [
      {
        data: [1, 5, 8, 5, 0, 4, 2, 46],
        backgroundColor: "#367CF6",
      },
    ],
  };

  initDataDelivery() {
    const subs = [] as any[];

    this.updates.map((update: any) => {
      subs.push(
        this.sourceService.getMultipleDelays(update.sources).pipe(
          map((delays: any) => ({
            delays: delays,
            batch_id: update.batch_id,
          })),
        ),
      );
    });

    const bigSub = forkJoin(subs);
    return bigSub.pipe(
      map((res) => {
        res.map(
          (delay: any) =>
            (this.sources_delays[delay.batch_id] = this.cleanDelays(
              delay.delays,
            )),
        );
        this.delays_data = { ...this.sources_delays["0"] };
        console.log(this.delays_data);
        console.log(this.sources_delays);
      }),
    );
  }

  setNewStep(new_step: string, update: any) {
    this.updates_current_steps[update.batch_id] = new_step;
  }

  cleanDelays(delays: any) {
    console.log(Object.keys(delays));
    const final = { months: [] } as Record<string, any>;

    final["months"] = Object.keys(delays);

    Object.keys(delays).map((month: string) =>
      Object.keys(delays[month]).map((source_name: string) =>
        final[source_name]
          ? final[source_name].push(delays[month][source_name])
          : (final[source_name] = [delays[month][source_name]]),
      ),
    );
    let labels = final["months"];
    if (labels.length === 1) {
      labels = ["", ...labels, ""];
    }

    const delays_data = {
      labels: labels,
      datasets: [] as any[],
    };

    Object.keys(final)
      .filter((key: string) => key !== "months")
      .map((source_name: string) =>
        delays_data.datasets.push(buildChartData(final[source_name])),
      );
    console.log(delays_data);
    return { ...delays_data };
  }

  capitalizeFirstLetter(string: string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
  }

  loadSummaryTables() {
    const subs = [];
    for (const update of this.updates) {
      if (isNaN(update.batch_id)) continue;

      subs.push(
        this.sourceService.getUpdateSummaryTable(update.batch_id).pipe(
          map((res) => {
            const cleaned = this.cleanSummaryTable(res);
            this.summary_tables[cleaned.batch_id] = cleaned.table.map(
              (row: any) => ({
                ...row,
                batch_step: this.capitalizeFirstLetter(row.batch_step),
              }),
            );
          }),
        ),
      );
    }

    return forkJoin(subs);
  }

  summary_tables: Record<string, any[]> = {};

  cleanSummaryTable(table: any[]) {
    let batch_id;
    if (table.length > 0) batch_id = table[0].batch_id;
    else batch_id = "Unknown";
    table = convertAllKPIsToIntegers(table);
    return { table: reOrderSummary(table), batch_id: batch_id };
  }

  fixIntegersInKPIsObject(KPIObject: Record<string, any>) {
    const final_object = {} as Record<string, any>;
    Object.keys(KPIObject).map(
      (key: string) =>
        (final_object[key] = Number.isNaN(parseInt(KPIObject[key]))
          ? KPIObject[key]
          : parseInt(KPIObject[key])),
    );

    return final_object;
  }

  fixIntegersInKPIsTable(KPI: Record<string, any>) {
    let final_table = {} as Record<string, any>;

    Object.keys(KPI).map((key: string) => {
      if (Array.isArray(KPI[key]))
        final_table[key] = KPI[key].map((data_point: any) =>
          this.fixIntegersInKPIsObject(data_point),
        );
      else final_table = KPI[key];
    });

    return final_table;
  }

  augmentKPIsWithPathUnderKey = (obj: { KPI: Record<string, any[]> }) => {
    let final_KPI = {} as Record<string, any>;
    Object.keys(obj.KPI).map((batch_id: string) => {
      final_KPI[batch_id] = {
        ...obj,
        KPI: augmentKPIsWithPaths(obj.KPI[batch_id]),
      };
    });

    return { ...obj, KPI: final_KPI };
  };

  loadStepKPIs() {
    const subs = [];
    for (const step of this.stepsWithKPIs) {
      subs.push(
        this.sourceService
          .getStepKPI(step)
          .pipe(
            map((KPI: any) => {
              return this.fixIntegersInKPIsTable(KPI);
            }),
          )
          .pipe(map((KPI: any) => ({ step: step, KPI: KPI })))
          .pipe(map((KPI: any) => (KPI.KPI.id ? { ...KPI, KPI: {} } : KPI)))
          .pipe(
            map((KPI: any) => {
              return this.augmentKPIsWithPathUnderKey(KPI);
              console.log("sample ", KPI);
              return KPI;
            }),
          ),
      );
    }

    const update_IDs = this.updates.map((update: any) => update.batch_id);
    const bigSub = forkJoin(subs);
    return bigSub.pipe(
      map((res) => {
        console.log(res);
        const final = {} as Record<string, any>;
        this.stepsWithKPIs.map((step: string) => {
          final[step] = {};
          update_IDs.map((update_id: any) => {
            final[step][update_id] = wrapArrayInTreeNode(
              [],
              this.capitalizeFirstLetter(
                step !== "consolidation" ? step : "cso_product",
              ),
              true,
              null,
            );
          });
        });
        console.log(final);

        res.map((value: any) =>
          Object.keys(value.KPI)
            .map((batch_id: string) => {
              console.log("values = ", value);
              final[value.step][batch_id] = [
                ...this.createGroupingFromKPIData(
                  value.step,
                  value.KPI[batch_id],
                ),
              ];
            })
            .flat(),
        );

        //Object.keys(final).map((step: string) => (final[step] = (this.fixStepInformation(final[step]))));

        console.log(final);
        this.stepKPIs = { ...final };
      }),
    );
  }

  removeGroupName(KPI: any) {
    return { ...KPI, field_name: KPI.field_name.split(".")[1] };
  }

  addLeadingZero(number: number) {
    return number < 10 ? `0${number}` : number.toString();
  }

  fixReferencingKPIs(KPIs: any[]) {
    return KPIs.map((KPI: any) =>
      (KPI["field_name"] as string).startsWith("prio_rec_molecules")
        ? {
            ...KPI,
            field_name: "rec_molecules." + KPI["field_name"].split(".")[0],
          }
        : KPI,
    );
  }

  groupBy(data: any[], field_name: string) {
    const uniqueGroups = new Set<string>();
    data.map((data_point: any) =>
      uniqueGroups.add(data_point[field_name].split(".")[0]),
    );
    console.log("unique_group_scleaning ", Array.from(uniqueGroups));
    const final_KPIS = {} as Record<string, any[]>;
    Array.from(uniqueGroups).map(
      (unique_group: string) =>
        (final_KPIS[unique_group] = data
          .filter(
            (data_point: any) =>
              data_point[field_name].split(".")[0] === unique_group,
          )
          .map((KPI: any) => removeGroupNameFromKPI(KPI))),
    );
    console.log("final processing ", final_KPIS);
    return final_KPIS;
  }

  createGroupingFromKPIData(step: string, KPIs: { KPI: any[] }) {
    if (step === "cleaning") {
      let groupedData = this.groupBy(KPIs.KPI, "field_name");
      Object.keys(groupedData).map((group_name: string) => {
        groupedData[group_name] = this.orderKPIs(
          groupedData[group_name],
          "consolidation",
          "cso_product",
        );
      });

      return wrapMultipleInTreeNode(groupedData, true);
    } else if (step === "referencing") {
      KPIs.KPI = this.fixReferencingKPIs(KPIs.KPI);
      console.log(
        "ref KPIs = ",
        KPIs.KPI.filter((KPI: any) =>
          KPI["field_name"].startsWith("rec_molecules"),
        ).map((KPI: any) => KPI["field_name"]),
      );
    }

    const unique_groups = new Set<string>();
    const finalKPIs = {} as Record<string, any>;
    KPIs.KPI.map((KPI: any) =>
      unique_groups.add(KPI["field_name"].split(".")[0]),
    );
    console.log("groups: ", Array.from(unique_groups));
    if (step === "referencing")
      console.log("ref groups: ", Array.from(unique_groups));
    Array.from(unique_groups).map((unique_group: string) => {
      const current_group_KPIs = KPIs.KPI.filter(
        (KPI: any) => KPI.field_name.split(".")[0] === unique_group,
      ).map((KPI: any) => this.removeGroupName(KPI));

      console.log("current KPIs ", current_group_KPIs);
      console.log("group_name=", unique_group);
      finalKPIs[this.capitalizeFirstLetter(unique_group)] = this.orderKPIs(
        current_group_KPIs,
        step,
        unique_group,
      );
    });

    return wrapMultipleInTreeNode(finalKPIs, true);
  }

  orderKPIs(KPIs: any[], step: string, groupName: string) {
    if (groupName === "cso_product") {
      return KPIs;
    }

    if (KPIs.find === undefined)
      alert("find is undefined groupName: " + groupName);
    //@TODO re-enable ordering
    if (groupName === "prio_rec_molecules") {
      groupName = "rec_molecules";
    } else if (groupName === "nfc_recode") {
      return KPIs;
    }

    const order = this.KPIOrderByGroup[step][groupName];
    if (step === "cleaning") console.log("cleaning order = ", order);
    if (!order) return KPIs;

    if (order) console.log("order for ", step, groupName, "is", order);

    const orderedKPIs = new Set<any>();

    for (const KPI_name of order) {
      const item = KPIs.find(
        (kpi: any) => kpi["field_name"].toLowerCase() === KPI_name,
      );

      if (item) {
        orderedKPIs.add(item);
      }
    }

    //Add any remaining items
    const remaining = KPIs.filter(
      (sales_data: any) => !orderedKPIs.has(sales_data),
    );

    for (const item of remaining) {
      orderedKPIs.add(item);
    }

    return Array.from(orderedKPIs);
  }

  salesInformation = {} as Record<string, any>;

  loadStepSalesInformation() {
    const subs = [];
    console.log(this.sources);
    for (const source of this.sources) {
      console.log("source: ", this.sources);
      subs.push(
        this.sourceService.getSalesInformation(source).pipe(
          map((sales_information: any) => ({
            sales_information: sales_information,
            source_name: source,
          })),
        ),
      );
    }

    const bigSub = forkJoin(subs);
    return bigSub.pipe(
      map((res) => {
        res.map(
          (information: any) =>
            (this.salesInformation[information.source_name] =
              information.sales_information),
        );
      }),
    );
  }

  filterComponentValues() {
    return Object.values(this.filters);
  }

  getTitle() {
    const statusLabels: Record<string, string> = {
      all: "All Updates",
      in_progress: "In Progress Updates",
      pending: "Pending Updates",
      completed: "Completed Updates",
    };

    if (this.selected_status) {
      return statusLabels[this.selected_status];
    }

    return "";
  }

  getSubMenuDisplayName() {
    const statusDisplayNames: Record<string, string> = {
      all: "All Updates",
      in_progress: "In Progress",
      pending: "Pending",
      completed: "Completed",
    };

    if (this.selected_status) {
      return statusDisplayNames[this.selected_status];
    }

    return "";
  }

  selectedFilterChanged(changeEvent: any) {
    const filterName = changeEvent.name;
    const event = changeEvent.event;

    if (event.value.length === 0) {
      this.filters[filterName].selected_values = ["all"];
      return;
    }

    const difference = arrayDifference(
      event.value,
      this.filters[filterName].old_selected_values,
    );

    if (arrayIsAll(difference))
      this.filters[filterName].selected_values = ["all"];
    else if (arrayIsAll(this.filters[filterName].old_selected_values))
      this.filters[filterName].selected_values = difference;
    else
      this.filters[filterName].selected_values = removeAll(
        this.filters[filterName].selected_values,
      );

    this.filters[filterName].old_selected_values =
      this.filters[filterName].selected_values;
  }

  timeConverter(UNIX_timestamp: number, in_milliseconds = true) {
    if (in_milliseconds) return new Date(UNIX_timestamp * 1000);
    else return new Date(UNIX_timestamp);
  }
}

function isValidDate(dateString: string) {
  const toValidate = new Date(dateString);

  return !Number.isNaN(toValidate.getDate());
}

//TODO parametrize and reuse in home component
export function calculateStepsForEmrData(update: any, read_from_data = true) {
  console.log("called steps_data", update["steps_data"]);
  /* Possible jobs status from AWS API reference
     FAILED
     PENDING
     RUNNING
     COMPLETED
    */

  const stages_list = [
    "cleaning",
    "consolidation",
    "referencing",
    "recoding",
    "categorization",
    "transcoding",
  ];
  const steps_data = update["steps_data"];

  const completedSteps = ["collection"] as string[];
  stages_list.map((step: string) => {
    let currentStepInfo = steps_data.find(
      (step_data: any) => step_data["step"] === step,
    );
    if (
      currentStepInfo &&
      isValidDate(currentStepInfo["created_at"]) &&
      isValidDate(currentStepInfo["updated_at"])
    )
      completedSteps.push(step);
  });

  const runningCondition = (stepInfo: any) =>
    isValidDate(stepInfo["created_at"]) && stepInfo["updated_at"] === "Unknown";

  const runningStep = steps_data.filter(runningCondition);

  const runningStages = runningStep.map((step: any) => step.step);

  console.log("steps running is", runningStages);

  const step_info = {} as Record<string, any>;
  step_info["collection"] = { duration: 50, state: stepState.completed };

  stages_list.map((stage: any) => {
    console.log("current_stage = ", runningStages.includes(stage));
    if (completedSteps.includes(stage)) {
      //TODO: remember to fix timzone issues
      const info = steps_data.find((step: any) => step["step"] === stage);
      const created_at = new Date(info["created_at"]);
      const updated_at = new Date(info["updated_at"]);

      const duration = Math.floor(
        Math.abs(created_at.valueOf() - updated_at.valueOf()),
      );

      step_info[stage] = { duration: duration, state: stepState.completed };
      console.log("step_info, ", steps_data);
    } else if (runningStages.includes(stage)) {
      //TODO: remember to fix timzone issues
      const info = steps_data.find((step: any) => step["step"] === stage);
      const created_at = new Date(info["created_at"]);
      const now = getNowInUTC();

      const duration = Math.floor(
        Math.abs(created_at.valueOf() - now.valueOf()),
      );
      step_info[stage] = { duration: duration, state: stepState.in_progress };
      console.log("running info is ", step_info[stage]);
    } else {
      step_info[stage] = { duration: "", state: stepState.pending };
    }
  });
  console.log("steps_info for update ", step_info);

  return step_info;
}

export function getNowInUTC() {
  const now = new Date();
  const utcTime = now.getTime() + now.getTimezoneOffset() * 60000;
  return new Date(utcTime);
}

export function convertDateToUTC(date: Date) {
  const utcTime = date.getTime() + date.getTimezoneOffset() * 60000;
  return new Date(utcTime);
}
