import { Component, EventEmitter, Output } from "@angular/core";
import { ConfirmationService } from "primeng/api";
import { Source } from "src/app/core/models/source";
import { SourceService } from "src/app/core/services/source.service";
import { fixSourceObjects } from "src/app/data-source-dialog/data-source-dialog.component";

@Component({
  selector: "new-data-source-dialog",
  templateUrl: "./new-data-source-dialog.component.html",
  styleUrls: ["./new-data-source-dialog.component.scss"],
})
export class NewDataSourceDialogComponent {
  source: Source | null = null;
  selectedFile: File | null = null;
  isValid = true;
  formSubmitted = false;

  constructor(
    private sourceService: SourceService,
    private confirmationDialog: ConfirmationService
  ) {}

  reload() {
    this.formSubmitted = false;
    this.isValid = true;
  }

  sourceInformationUpdated(source: Source) {
    this.source = { ...source };
  }

  validationStateChanged(isValid: boolean) {
    this.isValid = isValid;
  }

  updateSelectedFile(newFile: File | null) {
    this.selectedFile = newFile;
  }

  submitData() {
    this.formSubmitted = true;
    if (this.isValid) {
      if (this.source) {
        const sourceToSubmit = fixSourceObjects(this.source);
        if (!this.source) return;

        //@TODO check is this OK: to set a new source to pending state
        this.source.state = "pending";

        if (sourceToSubmit.current_update) {
          this.source.current_update = this.formatDate(
            new Date(sourceToSubmit.current_update)
          );
        } else sourceToSubmit.current_update = undefined;

        if (sourceToSubmit.end_period)
          this.source.end_period = this.formatDate(
            new Date(sourceToSubmit.end_period)
          );
        else sourceToSubmit.end_period = undefined;

        if (sourceToSubmit.next_update)
          this.source.next_update = this.formatDate(
            new Date(sourceToSubmit.next_update)
          );
        else this.source.next_update = undefined;

        if (sourceToSubmit.start_date)
          this.source.start_date = this.formatDate(
            new Date(sourceToSubmit.start_date)
          );
        else sourceToSubmit.start_date = undefined;

        sourceToSubmit.data_upload = this.selectedFile;
        this.sourceService.createSource(this.source).subscribe((res) => {
          this.sourceService.notifySourceUpdate();
          this.closeDialog();
        });
      }
    } else {
      this.formSubmitted = true;
    }
  }

  closeDialog() {
    this.confirmationDialog.close();
  }

  formatDate(date: Date) {
    return `${date.getFullYear()}-${
      date.getMonth() + 1
    }-${date.getDate()}T00:00`;
  }
}
