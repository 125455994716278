//TODO: replace other constants in components to constants like these
export const KPIHeaders = [
  { header: "Current records", field: "current_records" },
  { header: "New Records", field: "new_records" },
  { header: "Unique Values", field: "unique_values" },
  { header: "Unique New Values", field: "unique_new_values" },
  { header: "Undefined Values (%)", field: "total_blank_values" },
  { header: "New Undefined Values (%)", field: "total_new_blank_values" },
];

export const stepKPIsHeaders = [
  { header: "Data", field: "field_name" },
  ...KPIHeaders,
];

export const summary_table_headers = [
  { name: "", key: "batch_step" },
  ...toKeyName(KPIHeaders),
];

export const attributes_information_headers = [
  { name: "Field", key: "field" },
  ...toKeyName(KPIHeaders),
];

function toKeyName(arr: { header: string; field: string }[]) {
  return arr.map((item: any) => ({
    key: item.field,
    name: item.header,
  }));
}
