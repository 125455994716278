export function buildChartData(data: any[], color = "#367CF6") {
  if (data.length === 1) {
    data = ["", ...data, ""];
  }
  return {
    data: [...data],
    barPercentage: 0.4,
    backgroundColor: color,
  };
}

export function buildMultipleChartData(multiData: any[][]) {
  const colors = ["#367CF6", "#F59C4A"];
  return multiData.map((data: any[], index: number) =>
    buildChartData(data, colors[index % 2]),
  );
}
