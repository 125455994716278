import { Pipe, PipeTransform } from "@angular/core";
import { UndoQueue } from "../undoQueue";

@Pipe({
  name: "applyChangesFilter",
  pure: false,
})
export class ApplyChangesFilter implements PipeTransform {
  transform(
    rule: any,
    changeLog: UndoQueue,
    primary_key: string[] | undefined,
  ): any {
    if (rule["metadata"] && rule["metadata"]["inserted"]) return rule;
    const data = ApplyChanges(rule, changeLog, primary_key);
    if (data["category_master"] === undefined) console.log("it's undefined");
    return data;
  }
}

export function ApplyChanges(
  rule: any,
  changeLog: UndoQueue,
  primary_key: string[] | undefined,
): any {
  if (!primary_key || Object.keys(primary_key).length === 0) return rule;

  const primary_key_value = {} as Record<string, any>;
  primary_key.map(
    (key: string) =>
      (primary_key_value[key] = (rule as Record<string, any>)[key]),
  );

  const items = changeLog.getItems();
  const ruleChanges = items
    .filter((change: any) => change.contains(primary_key_value))
    .map((change: any) => (rule = change.apply(rule)));

  if (ruleChanges.length === 0) {
    const columns = Object.keys(rule);
    columns.map((column: string) => {
      if (column.startsWith("original_")) return;
      else rule[column] = rule["original_" + column];
    });
  }
  return rule;
}
