import {
  Component,
  ElementRef,
  HostListener,
  ViewChild,
  OnInit,
} from "@angular/core";
import { ConfirmationService, MessageService } from "primeng/api";
import {
  Router,
  Event,
  ActivatedRoute,
  RoutesRecognized,
} from "@angular/router";
import { ToastService } from "../core/services/toast.service";
import { Menu } from "primeng/menu";
import { AuthService } from "../core/services/auth.service";
import { AccountService } from "../core/services/account.service";
import { Location } from "@angular/common";
import { filter, pairwise } from "rxjs";

@Component({
  selector: "app-dashboard-layout",
  templateUrl: "./dashboard-layout.component.html",
  styleUrls: ["./dashboard-layout.component.scss"],
  providers: [MessageService],
})
export class DashboardLayoutComponent implements OnInit {
  navigateToDirection(mode: string) {
    if (mode === "backwards" && this.locationService.path() === "") return;
    if (mode === "backwards") this.locationService.back();
    else if (mode === "forward") this.locationService.forward();
  }

  @ViewChild("op", { static: false }) profileMenu: Menu | undefined;
  items = [
    { label: "Settings", value: "settings", icon: "settings.svg" },
    { label: "Sign out", value: "logout", icon: "sign_out.svg" },
  ];

  menuChosen(event: any) {
    if (event === "settings") {
      this.router.navigate(["/settings"]);
    } else if (event === "logout") {
      this.authService.doLogout();
    }

    console.log("menu clicked", event);
  }

  profileMenuIsShown = false;

  showProfileMenu() {
    this.profileMenuIsShown = true;
  }

  @ViewChild("sub_menu")
  sub_menu: ElementRef | null = null;

  @ViewChild("intelligence_center_menu")
  intelligence_center_menu: ElementRef | null = null;

  @ViewChild("profile_picture")
  profile_picture: ElementRef | null = null;

  sub_menu_display = "block";
  sub_menu_toggable = false;

  fileLockMenuIsActive = false;

  currentSubRouteName: string | null = null;
  currentUser: any;

  constructor(
    private confirmationService: ConfirmationService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private messageService: MessageService,
    private toastService: ToastService,
    private authService: AuthService,
    public accountService: AccountService,
    private locationService: Location
  ) {}

  canNavigate = true;
  urlHistory: string[] = [];

  ngOnInit() {
    console.log("account: ", this.accountService.getProfilePictureLink())
    this.locationService.onUrlChange((url: string, state: unknown) => {
      this.urlHistory.push(url);
      console.log("maga url", this.locationService.path());
    });

    this.currentUser = this.authService.getCurrentUser();
    this.toastService.toastEvent.subscribe((params: any) => {
      this.messageService.add(params);
    });

    this.router.events.subscribe((event: Event) => {
      if (this.activatedRoute.snapshot.children.length > 0) {
        this.currentSubRouteName =
          this.activatedRoute.snapshot.children[0].data["name"];
      }
    });
  }

  navigationButtonIcon(iconDirection: string) {
    let icon = "";
    if (iconDirection === "right")
      icon = this.canNavigate ? "right_enabled.svg" : "right_disabled.svg";
    else if (iconDirection === "left")
      icon = this.canNavigate ? "left_enabled.svg" : "left_disabled.svg";

    return `assets/icons/menu/menu_chevron/chevron_${icon}`;
  }

  showSubMenu() {
    this.sub_menu_display = "block";
    this.fileLockMenuIsActive = true;
  }

  hideSubMenu() {
    this.sub_menu_display = "none";
    this.fileLockMenuIsActive = false;
  }

  @HostListener("document:click", ["$event"])
  clickedOutsideSubMenu(event: { target: any }) {
    if (!this.profile_picture?.nativeElement.contains(event.target))
      this.profileMenuIsShown = false;

    const can_close_menu = localStorage.getItem("can_close_menu") === "true";

    if (!this.sub_menu_toggable) return;
    if (!can_close_menu) return;

    if (this.sub_menu === null) return;
    if (this.intelligence_center_menu === null) return;

    if (
      !this.sub_menu.nativeElement.contains(event.target) &&
      !this.intelligence_center_menu.nativeElement.contains(event.target) &&
      this.sub_menu_display === "block"
    ) {
      this.hideSubMenu();
    }
  }

  closeButtonClicked() {
    localStorage.setItem("can_close_menu", "true");
    this.hideSubMenu();
  }

  openNewSourceModal() {
    this.confirmationService.confirm({
      header: "Confirmation",
      key: "new_source_dialog",
      message: "Please confirm to proceed moving forward.",
      acceptIcon: "pi pi-check mr-2",
      rejectIcon: "pi pi-times mr-2",
      rejectButtonStyleClass: "p-button-sm",
      acceptButtonStyleClass: "p-button-outlined p-button-sm",
      accept: () => {
        alert("yes");
      },
      reject: () => {
        alert("yes");
      },
    });
  }

  showToast(params: any) {
    this.messageService.add(params);
  }
}
